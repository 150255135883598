import FilesystemIcon from "./img/gatsby-source-filesystem-plugin-icon.png"
import ContentfulIcon from "./img/gatsby-source-contentful-plugin-icon.png"
import ImageIcon from "./img/gatsby-image-plugin-icon.png"
import SharpIcon from "./img/gatsby-plugin-sharp-plugin-icon.png"
import HelmetIcon from "./img/gatsby-plugin-react-helmet-plugin-icon.png"
import OfflineIcon from "./img/gatsby-plugin-offline-plugin-icon.png"
import TypescriptIcon from "./img/gatsby-plugin-typescript-plugin-icon.png"
import MdxIcon from "./img/gatsby-plugin-mdx-plugin-icon.png"
import RemarkIcon from "./img/gatsby-transformer-remark-plugin-icon.png"
import SitemapIcon from "./img/gatsby-plugin-sitemap-plugin-icon.png"
import AirtableIcon from "./img/gatsby-source-airtable-plugin-icon.png"
import S3Icon from "./img/gatsby-plugin-s3-plugin-icon.png"
import NetlifyCmsIcon from "./img/gatsby-source-netlify-plugin-icon.png"
import WordPressIcon from "./img/gatsby-source-wordpress-plugin-icon.png"
import DrupalIcon from "./img/gatsby-source-drupal-plugin-icon.png"
import SanityIcon from "./img/gatsby-source-sanity-plugin-icon.png"
import DatoIcon from "./img/gatsby-source-datocms-plugin-icon.png"
import StrapiIcon from "./img/gatsby-source-strapi-plugin-icon.png"
import KenticoIcon from "./img/gatsby-source-kentico-plugin-icon.png"
import AgilityIcon from "./img/gatsby-source-agilitycms-plugin-icon.png"
import FlotiqIcon from "./img/gatsby-source-flotiq-plugin-icon.png"
import ContentstackIcon from "./img/gatsby-source-contentstack-icon.png"
import PrismicIcon from "./img/gatsby-source-prismic-icon.png"
import GraphCMSIcon from "./img/gatsby-source-graphcms-plugin-icon.png"
import EmotionIcon from "./img/gatsby-plugin-emotion-plugin-icon.png"
import StyledComponentsIcon from "./img/gatsby-plugin-styled-components-plugin-icon.png"
import SassIcon from "./img/gatsby-plugin-sass-plugin-icon.png"
import PostcssIcon from "./img/gatsby-plugin-postcss-plugin-icon.png"
import PurgecssIcon from "./img/gatsby-plugin-purgecss-plugin-icon.png"
import LessIcon from "./img/gatsby-plugin-less-plugin-icon.png"
import MaterialUiIcon from "./img/gatsby-plugin-material-ui-plugin-icon.png"
import LinariaIcon from "./img/gatsby-plugin-linaria-plugin-icon.png"
import GaIcon from "./img/gatsby-plugin-google-analytics-plugin-icon.png"
import GtmIcon from "./img/gatsby-plugin-google-tagmanager-plugin-icon.png"
import SegmentIcon from "./img/gatsby-plugin-segment-js-plugin-icon.png"
import HotjarIcon from "./img/gatsby-plugin-hotjar-plugin-icon.png"
import MatomoIcon from "./img/gatsby-plugin-matomo-plugin-icon.png"
import MixpanelIcon from "./img/gatsby-plugin-mixpanel-plugin-icon.png"
import ShopifyIcon from "./img/gatsby-source-shopify-plugin-icon.png"
import SnipcartIcon from "./img/gatsby-source-snipcart-plugin-icon.png"
import BigcommerceIcon from "./img/gatsby-source-bigcommerce-plugin-icon.png"
import StripeIcon from "./img/gatsby-source-stripe-plugin-icon.png"
import Auth0Icon from "./img/gatsby-theme-auth0-ts-plugin-icon.png"
import AlgoliaIcon from "./img/gatsby-plugin-algolia-plugin-icon.png"
import ImgixIcon from "./img/gatsby-plugin-imgix-icon.png"
import VimeoIcon from "./img/gatsby-source-vimeo-icon.png"
import CloudinaryIcon from "./img/gatsby-source-cloudinary-icon.png"
import Fallback from "./img/fallback-plugin-icon.png"

export const topPlugins = [
  {
    name: "source-filesystem",
    pluginName: "gatsby-source-filesystem",
    image: FilesystemIcon,
    description:
      "Gatsby source plugin for building websites from local data. Markdown, JSON, images, YAM…",
  },
  {
    name: "Contentful",
    pluginName: "gatsby-source-contentful",
    image: ContentfulIcon,
    description:
      "Gatsby source plugin for building websites using the Contentful CMS as a data source",
  },
  {
    name: "gatsby-plugin-image",
    pluginName: "gatsby-plugin-image",
    image: ImageIcon,
    description:
      "gatsby-image replacement adding a StaticImage component and updating GatsbyImage …",
  },
  {
    name: "plugin-sharp",
    pluginName: "gatsby-plugin-sharp",
    image: SharpIcon,
    description:
      "Wrapper of the Sharp image manipulation library for Gatsby plugins",
  },
  {
    name: "React Helmet",
    pluginName: "gatsby-plugin-react-helmet",
    image: HelmetIcon,
    description:
      "Manage document head data with react-helmet. Provides drop-in server rendering support for Gat…",
  },
  {
    name: "Offline",
    pluginName: "gatsby-plugin-offline",
    image: OfflineIcon,
    description: "Gatsby plugin which sets up a site to be able to run offline",
  },
  {
    name: "TypeScript",
    pluginName: "gatsby-plugin-typescript",
    image: TypescriptIcon,
    description: "Adds TypeScript support to Gatsby",
  },
  {
    name: "MDX",
    pluginName: "gatsby-plugin-mdx",
    image: MdxIcon,
    description: "MDX integration for Gatsby",
  },
  {
    name: "Remark",
    pluginName: "gatsby-transformer-remark",
    image: RemarkIcon,
    description:
      "Gatsby transformer plugin for Markdown using the Remark library and ecosystem",
  },
  {
    name: "Sitemap",
    pluginName: "gatsby-plugin-sitemap",
    image: SitemapIcon,
    description:
      "Gatsby plugin that automatically creates a sitemap for your site",
  },
  {
    name: "Airtable",
    pluginName: "gatsby-source-airtable",
    image: AirtableIcon,
    description:
      "Gatsby Airtable Source with Linked Nodes, Multiple Tables and File Nodes",
  },
  {
    name: "Amazon S3",
    pluginName: "gatsby-plugin-s3",
    image: S3Icon,
    description: "Enables you to deploy your gatsby site to a S3 bucket",
  },
]
export const cms = [
  {
    name: "Contentful",
    pluginName: "gatsby-source-contentful",
    image: ContentfulIcon,
  },
  {
    name: "Netlify CMS",
    pluginName: "gatsby-plugin-netlify-cms",
    image: NetlifyCmsIcon,
  },
  {
    name: "WordPress",
    pluginName: "gatsby-source-wordpress",
    image: WordPressIcon,
  },
  {
    name: "Drupal",
    pluginName: "gatsby-source-drupal",
    image: DrupalIcon,
  },
  {
    name: "Sanity",
    pluginName: "gatsby-source-sanity",
    image: SanityIcon,
  },
  {
    name: "DatoCMS",
    pluginName: "gatsby-source-datocms",
    image: DatoIcon,
  },
  {
    name: "Strapi",
    pluginName: "gatsby-source-strapi",
    image: StrapiIcon,
  },
  {
    name: "Kontent",
    pluginName: "@kentico/gatsby-source-kontent",
    image: KenticoIcon,
  },
  {
    name: "Agility",
    pluginName: "@agility/gatsby-source-agilitycms",
    image: AgilityIcon,
  },
  {
    name: "Flotiq",
    pluginName: "gatsby-source-flotiq",
    image: FlotiqIcon,
  },
  {
    name: "Contentstack",
    pluginName: "gatsby-source-contentstack",
    image: ContentstackIcon,
  },
  {
    name: "Prismic",
    pluginName: "gatsby-source-prismic",
    image: PrismicIcon,
  },
  {
    name: "GraphCMS",
    pluginName: "gatsby-source-graphcms",
    image: GraphCMSIcon,
  },
]
export const css = [
  {
    name: "Emotion",
    pluginName: "gatsby-plugin-emotion",
    image: EmotionIcon,
  },
  {
    name: "Styled Components",
    pluginName: "gatsby-plugin-styled-components",
    image: StyledComponentsIcon,
  },
  {
    name: "Sass",
    pluginName: "gatsby-plugin-sass",
    image: SassIcon,
  },
  {
    name: "PostCSS",
    pluginName: "gatsby-plugin-postcss",
    image: PostcssIcon,
  },
  {
    name: "PurgeCSS",
    pluginName: "gatsby-plugin-purgecss",
    image: PurgecssIcon,
  },
  {
    name: "Less",
    pluginName: "gatsby-plugin-less",
    image: LessIcon,
  },
  {
    name: "MaterialUI",
    pluginName: "gatsby-plugin-material-ui",
    image: MaterialUiIcon,
  },
  {
    name: "Linaria",
    pluginName: "gatsby-plugin-linaria",
    image: LinariaIcon,
  },
]
export const analytics = [
  {
    name: "Google Analytics",
    pluginName: "gatsby-plugin-google-analytics",
    image: GaIcon,
  },
  {
    name: "Google Tag Manager",
    pluginName: "gatsby-plugin-google-tagmanager",
    image: GtmIcon,
  },
  {
    name: "Segment",
    pluginName: "gatsby-plugin-segment-js",
    image: SegmentIcon,
  },
  {
    name: "Hotjar",
    pluginName: "gatsby-plugin-hotjar",
    image: HotjarIcon,
  },
  {
    name: "Matomo",
    pluginName: "gatsby-plugin-matomo",
    image: MatomoIcon,
  },
  {
    name: "Mixpanel",
    pluginName: "gatsby-plugin-mixpanel",
    image: MixpanelIcon,
  },
]
export const eCommerce = [
  {
    name: "Shopify",
    pluginName: "gatsby-source-shopify",
    image: ShopifyIcon,
  },
  {
    name: "Snipcart",
    pluginName: "gatsby-plugin-snipcart-advanced",
    image: SnipcartIcon,
  },
  {
    name: "Bigcommerce",
    pluginName: "gatsby-source-bigcommerce",
    image: BigcommerceIcon,
  },
  {
    name: "Stripe",
    pluginName: "gatsby-source-stripe",
    image: StripeIcon,
  },
  {
    name: "theme-auth0",
    pluginName: "gatsby-theme-auth0-ts",
    image: Auth0Icon,
  },
  {
    name: "Okta",
    pluginName: "gatsby-plugin-okta",
    image: Fallback,
  },
]

export const search = [
  {
    name: "Algolia",
    pluginName: "gatsby-plugin-algolia",
    image: AlgoliaIcon,
  },
  {
    name: "Elasticlunr",
    pluginName: "@gatsby-contrib/gatsby-plugin-elasticlunr-search",
    image: Fallback,
  },
  {
    name: "plugin-local-search",
    pluginName: "gatsby-plugin-local-search",
    image: Fallback,
  },
]
export const media = [
  {
    name: "imgix",
    pluginName: "@imgix/gatsby",
    image: ImgixIcon,
  },
  {
    name: "Cloudinary",
    pluginName: "gatsby-source-cloudinary",
    image: CloudinaryIcon,
  },
  {
    name: "Vimeo",
    pluginName: "gatsby-source-vimeo",
    image: VimeoIcon,
  },
]
